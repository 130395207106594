import './App.css';
import Home from './views/Home';

function App() {
  return (
    <div className="body App">
      <Home />
</div>
  );
}

export default App;
