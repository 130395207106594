import "./carde.css";

const jobs = [
    {
        icon: require("../img/integralia.png").default,
        position: "Consultant",
        enterprise: "Integralia",
        period: "January 2022 - present",
        activities: "Studies and training unit",
        direction: "Mexico City, México.",
    },
    {
        icon: require("../img/logog.png").default,
        position: "Electoral and Political Consultant",
        enterprise: "Freelance",
        period: "March 2021 - June 2021",
        activities: "Electoral field strategy, digital strategy and image consulting.",
        direction: "Jalisco, México.",
    },
    {
        icon: require("../img/logog.png").default,
        position: "Research Assistant at the Political Science Department",
        enterprise: "Instituto Tecnológico Autónomo de México (ITAM)",
        period: "October 2020 - April 2021",
        activities: "Datasets constructions and data cleaning",
        direction: "ITAM, Río Hondo 1, Altavista, Álvaro Obregón, 01080 Ciudad de México, CDMX.",
    },
    {
        icon: require("../img/Congreso.png").default,
        position: "Chief of Department, Coordination of Administration and Finances",
        enterprise: "Congress of the State of Jalisco, Mexico",
        period: "July 2020 - March 2021",
        activities: "Legislative work, initiatives and bills drafting.",
        direction: "Hidalgo # 222 Guadalajara, Jalisco, México.",
    },
    {
        icon: require("../img/Zapopan.png").default,
        position: "Delegate of the Social Committee",
        enterprise: "Anticorruption System of the Zapopan, Jalisco City Hall",
        period: "January 2020 - January 2021",
        activities: "Access without any restriction the information generated by the City Hall; Give opinion and make proposals on municipal policies; Propose to the Committee: Project drafts for interinstitutional and intergovernamental coordination for fiscalization and control of public resources, prevention, control and preventing actions to fight corruption and its causes; Projects to improve the instruments, guidelines and mechanisms for the operation of the Municipal System and its tools",
        direction: "Zapopan, Jalisco.",
    },
    {
        icon: require("../img/integralia.png").default,
        position: "Public Finance Research Assistant",
        enterprise: "Integralia Consultores",
        period: "April 2018 - 15 September 2018",
        activities: "Colaboration in research projects and elaboration of political risk analysis. Data collecting and making datasets for legislative reports and studies about public finances.",
        direction: "Integralia Consultores Goldsmith 37 11560 Ciudad de México, CDMX. (Ahora en Insurgentes Sur)",
    },
    {
        icon: require("../img/Consulado.jpg").default,
        position: "Intern at the Economic Affairs and Documentation Department",
        enterprise: "Mexican General Consulate at New York City",
        period: "July 2017",
        activities: "Making of a general report of the MAEX system of Condusef and attending inssuing of passports, electoral credentials and other official documentation.",
        direction: "27 E 39th St, New York, NY 10016, Estados Unidos.",
    },
    {
        icon: require("../img/Juanacatlan.jpg").default,
        position: "Consultant in public finance issues of the City Hall of Juanacatlán, Jalisco",
        enterprise: "Freelance",
        period: "June 2015 - December 2015",
        activities: "Review of the public finance documentation and its delivery to the Superior Audit of the State of Jalisco.",
        direction: "C. Independencia #1, Juanacatlán Centro, 45880 Juanacatlán, Jal.",
    },
    {
        icon: require("../img/UnMillon.png").default,
        position: "Project coordinator of  del proyecto Transparency and Accountability at Municipalities Measurement",
        enterprise: "Un Millón de Jóvenes Por México",
        period: "December 2014",
        activities: "Questionnaire design and coordination of data analysis team.",
        direction: "CDMX",
    },
    {
        icon: require("../img/IPEA.png").default,
        position: "Organizer of the panel 'Analysis of the Economic Competition Law'",
        enterprise: "IPEA-ITAM",
        period: "April 2014",
        activities: "President of Chapter IPEA-ITAM and activities coordinator.",
        direction: "ITAM, Río Hondo 1, Altavista, Álvaro Obregón, 01080 Ciudad de México, CDMX.",
    },
    {
        icon: require("../img/IPEA.png").default,
        position: "Organizer of Forum IPEA-ITAM: THE IMPACT OF MEXICAN STRUCTURAL REFORMS",
        enterprise: "IPEA-ITAM",
        period: "Marzo 2014",
        activities: "President of Chapter IPEA-ITAM and activities coordinator.",
        direction: "ITAM, Río Hondo 1, Altavista, Álvaro Obregón, 01080 Ciudad de México, CDMX.",
    },
    {
        icon: require("../img/Lideres.png").default,
        position: "Media Assistant",
        enterprise: "Líderes Mexicanos",
        period: "September 2013",
        activities: "Assistant speech writer and community manager at the award event of the 300 most influential people in Mexico: Comida Anual de Los 300 Líderes más influyentes de México 2013.",
        direction: "CDMX",
    },
]

function Card() {
    return <div id="Card">
        {jobs.map((job, i) => {
            return <div id="card-each" className="row" key={i} >
                <div className="col-2">
                    <img id="icon-card" src={job.icon} alt="" />
                </div>
                <div id="text-card" className="col-10">
                    <p className="fw-bold">Job Position: {job.position} </p>
                    <p>Period: {job.period}</p>
                    <p className="fw-bold"> {job.enterprise}</p>
                    <p className="fw-bold">Main Duties:</p>
                    <p>{job.activities}</p>
                    <div>
                        <div>
                            <p>{job.direction}</p>
                        </div>
                    </div>
                </div>
            </div>
        })}
    </div>
}

export default Card;